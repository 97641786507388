import { useEffect, Fragment, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { FormProvider, useForm } from "react-hook-form"
import { connect, useDispatch, useSelector } from "react-redux"
import { Menu } from "@headlessui/react"
import Tippy from "@tippyjs/react"
import { RootDispatch, RootState } from "store"
import * as yup from "yup"
import { useMsal } from "@azure/msal-react"
import { yupResolver } from "@hookform/resolvers/yup"
import { PoweredByLogo } from "assets/icons/navigation-icons/navigation-icons"

import { useWindowSize } from "common/hooks/useWindowSize"
import { UserRole } from "../../common/enums/UserRole.enum"
import { UserAllocatedWorkspaceModel } from "common/models/UserAllocatedWorkspaceModel"
import { UserModel } from "common/models/UserModel"
import { ConfirmationModal } from "common/modals/confirmation-modal/confirmation-modal"
import { SELECTED_WORKSPACE_ID } from "api/api"

import { USER_RIGHTS_DESCRIPTION } from "../../utils/constants"
import { logout } from "utils"
import { red } from "utils/colors"
import { useModal } from "context/modal-context"

import { TippyWrapper } from "components/tippyWrapper/tippyWrapper"
import { Checkbox } from "components/form-components/checkbox/checkbox"
import { Dropdown } from "components/dropdown/dropdown"
import { Icon, ICONS } from "components/icon/icon"
import { Select, SelectOption } from "../form-components/select/select"
import { NotificationsPopUp } from "../notifications/notifications-popup/notifications-popup"
import { Button } from "components/button/button"
import { ReactComponent as Logo } from "../../assets/images/logo.svg"
import "./navigation.scss"

const menuItems = [
  {
    name: <span>DASHBOARD</span>,
    path: "/main/dashboard",
    icon: ICONS.DASHBOARD,
  },
  {
    name: <span>SHARE-OF-SEARCH</span>,
    path: "/main/share-of-search",
    icon: ICONS.SHARE_OF_SHELF,
  },
  {
    name: <span>CATALOG</span>,
    path: "/main/audit-catalog",
    icon: ICONS.VIEW_AUDIT_CATALOG,
  },
  {
    name: <span>REPORTS</span>,
    path: "/main/reports",
    icon: ICONS.REPORTS,
  },
  {
    name: <span>CONTENT INSIGHTS</span>,
    path: "/main/content-insights",
    icon: ICONS.CONTENT_INSIGHTS,
  },
]

const schema = yup.object({
  isSwitchWorkspaceWarningDisabled: yup.boolean(),
})

type Props = ReturnType<typeof mapProps> & ReturnType<typeof mapDispatch>

export const NavigationComponent = ({
  account,
  workspaces,
  selectedWorkspaceId,
  workspaceAssignedPlans,
  availablePlans,
  notifications,
  selectWorkspace,
  updateUser,
  getWorkspaceAssignedPlans,
  getAvailablePlans,
}: Props) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { setModal } = useModal()
  const { width } = useWindowSize()
  const [user, setUser] = useState<UserModel>()
  const [isLoading, setIsLoading] = useState(true)
  const dispatch = useDispatch()
  const [workspaceOptions, setWorkspaceOptions] = useState([] as SelectOption[])
  const [notificationsCount, setNotificationsCount] = useState<number>(0)
  const [selectedWorkspace, setSelectedWorkspace] = useState<any>(null)
  const [isSwitchWorkspaceMenuOpen, setIsSwitchWorkspaceMenuOpen] = useState(false)
  const { instance } = useMsal()
  const selectedWorkspaceUserRole = useSelector(
    (state: RootState) => state.workspaces.selectedWorkspaceUserRole,
  )
  const userRole = Object.keys(UserRole).find(
    (key) => UserRole[key as keyof typeof UserRole] === selectedWorkspaceUserRole,
  )
  const currentUserRight = USER_RIGHTS_DESCRIPTION.find(
    (userRight) => userRight.name === userRole,
  )

  const userActions = [
    {
      name: "Account",
      path: "/main/account",
    },
    {
      name: "Billing & Plans",
      path: "/main/billing",
    },
    {
      name: "Current Workspace  ",
      path: `/main/workspaces/${selectedWorkspaceId}/edit/team-composition`,
    },
    {
      name: "Manage Workspaces",
      path: "/main/workspaces",
    },
    {
      name: "Invoices",
      path: "/main/invoices",
    },
  ]

  const toolsActions = [
    {
      name: "Customize Scorecards",
      path: "/main/grading",
    },
    {
      name: "Create Alerts",
      path: "/main/alerts",
    },
    {
      name: "Downloads",
      path: "/main/downloads",
    },
  ]

  useEffect(() => {
    if (workspaces) {
      setWorkspaceOptions(
        Object.keys(workspaces.workspaceMap).map((id) => ({
          value: id,
          label: workspaces.workspaceMap[id].name,
        })),
      )
    }
  }, [workspaces])

  useEffect(() => {
    getAvailablePlans()
  }, [])

  useEffect(() => {
    if (workspaces.workspaceMap) {
      setSelectedWorkspace(workspaces.workspaceMap[selectedWorkspaceId || ""])
    }
  }, [selectedWorkspaceId, workspaces.workspaceMap])

  useEffect(() => {
    if (localStorage.getItem(SELECTED_WORKSPACE_ID)) {
      getWorkspaceAssignedPlans().then(() => {
        setIsLoading(false)
      })
    }
  }, [localStorage.getItem(SELECTED_WORKSPACE_ID)])

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        localStorage.getItem(SELECTED_WORKSPACE_ID) !== null &&
        localStorage.getItem(SELECTED_WORKSPACE_ID) !== ""
      ) {
        dispatch.notifications.fetchAllNotifications()
      }
    }, 60000)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (
      localStorage.getItem(SELECTED_WORKSPACE_ID) !== null &&
      localStorage.getItem(SELECTED_WORKSPACE_ID) !== ""
    ) {
      dispatch.notifications.fetchAllNotifications()
    }
  }, [localStorage.getItem(SELECTED_WORKSPACE_ID)])

  useEffect(() => {
    if (notifications.length) {
      setNotificationsCount(
        notifications?.filter((notification) => !notification?.isRead).length,
      )
    }
  }, [notifications])

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      isSwitchWorkspaceWarningDisabled: false,
    },
  })

  const changeWorkspaceEnvironment = (workspace: UserAllocatedWorkspaceModel) => {
    if (workspace) {
      dispatch({ type: "RESET_WORKSPACE_DATA" })
      selectWorkspace(workspace)
      localStorage.setItem(SELECTED_WORKSPACE_ID, String(workspace.workspaceId))
      navigate("/", { replace: true })
    }
  }

  const changeWorkspaceWarningModalOnConfirm = (
    workspace: UserAllocatedWorkspaceModel,
    values: any,
  ) => {
    if (values.isSwitchWorkspaceWarningDisabled) {
      const newUserPreference = {
        ...account.user,
        userFlags: {
          ...account.user.userFlags,
          isSwitchWorkspaceWarningDisabled: values.isSwitchWorkspaceWarningDisabled,
        },
      }
      updateUser(newUserPreference)
    }
    changeWorkspaceEnvironment(workspace)
  }

  const changeWorkspace = (option: SelectOption) => {
    const workspace = workspaces.workspaceMap[option.value]
    if (workspace) {
      setUser(account.user)
      const isWarningDisabled = account.user.userFlags
        ? account.user.userFlags.isSwitchWorkspaceWarningDisabled
        : false
      if (isWarningDisabled) {
        return changeWorkspaceEnvironment(workspace)
      }
      return setModal(
        <FormProvider {...methods}>
          <form onSubmit={() => {}}>
            <ConfirmationModal
              onConfirm={() =>
                changeWorkspaceWarningModalOnConfirm(workspace, methods.getValues())
              }
              title="Switch Workspace"
              confirmLabel="Switch Workspace"
            >
              <div className="my-2 text-gray-500">
                You are about to switch to another workspace. By doing this, all your data
                will be adjusted accordingly and some of user permissions might change.
              </div>
              <Checkbox
                name="isSwitchWorkspaceWarningDisabled"
                label="Stop asking me this again"
                onChange={() => {
                  methods.setValue(
                    "isSwitchWorkspaceWarningDisabled",
                    !user?.userFlags.isSwitchWorkspaceWarningDisabled,
                  )
                }}
              />
            </ConfirmationModal>
          </form>
        </FormProvider>,
      )
    }
  }

  return (
    <nav className="navigation">
      <div className="top-container">
        <div className="grid items-center">
          <Link to="/main/dashboard" className="text-gray-900 font-bold text-xl">
            {selectedWorkspace && selectedWorkspace.customLogo !== null ? (
              <img
                className="object-contain w-auto"
                style={{ height: "3.2rem" }}
                src={"data:png;base64," + selectedWorkspace.customLogo}
              />
            ) : (
              <Logo />
            )}
          </Link>
        </div>
        <div className="right-button-group">
          <span className="switch-workspace">
            <span className="switch-workspace-label">Workspace</span>
            <div className="switch-workspace-input">
              <Select
                options={workspaceOptions}
                value={workspaces.selectedWorkspaceId}
                valueRender={(option) => (
                  <div className="selected-option-container">
                    <span className="selected-option">{option.label}</span>
                    {userRole !== undefined && (
                      <div className="flex items-center">
                        <span className="switch-workspace-role">{userRole}</span>
                        <span className="switch-workspace-role-divider" />
                      </div>
                    )}
                  </div>
                )}
                name="workspaceSelect"
                onChange={changeWorkspace}
                onFocus={() => setIsSwitchWorkspaceMenuOpen(true)}
                onBlur={() => setIsSwitchWorkspaceMenuOpen(false)}
                controlHeight={35}
                isSearchable
              />
              <Tippy
                content={
                  <div className="bg-white p-5 pb-2 shadow-lg" style={{ width: 350 }}>
                    <div className="mb-1 text-black text-caption-1 font-medium">
                      {`${currentUserRight?.name} Permission`}
                    </div>
                    <div className="text-gray-500 text-caption-1 mb-5">
                      {currentUserRight?.description}
                    </div>
                    <Button
                      variant="link"
                      className="secondary underline -ml-2 -mt-5"
                      onClick={() =>
                        navigate(
                          `/main/workspaces/${selectedWorkspaceId}/edit/team-composition`,
                        )
                      }
                    >
                      See workspace settings
                    </Button>
                  </div>
                }
                interactive
                placement="bottom"
                disabled={isSwitchWorkspaceMenuOpen}
              >
                <span className="tooltip-icon">
                  <Icon icon={ICONS.INFORMATION_CIRCLE_OUTLINED} size={4} />
                </span>
              </Tippy>
            </div>
          </span>

          <Button
            variant="outlined"
            style={{ height: 35 }}
            className="mr-2"
            onClick={() => navigate("/main/audits")}
          >
            My Audits
          </Button>

          <Dropdown
            style={{
              top: "50px",
              right: "-50px",
              borderRadius: "8px",
              border: "1px solid #ced4da",
            }}
            trigger={
              <Menu.Button
                className={`button icon-btn ${
                  location.pathname.includes(`/notifications`) && "active"
                }`}
                style={{ padding: 0 }}
              >
                <TippyWrapper
                  content={
                    <div className="tippy-wrapper-content -translate-y-2">
                      Notifications
                    </div>
                  }
                >
                  <div className="flex justify-center relative items-center h-10 w-8">
                    {notificationsCount > 0 && (
                      <div
                        style={{
                          height:
                            notificationsCount < 10
                              ? "16px"
                              : notificationsCount > 10 && notificationsCount < 100
                              ? "17px"
                              : "21px",
                          width:
                            notificationsCount < 10
                              ? "16px"
                              : notificationsCount > 10 && notificationsCount < 100
                              ? "17px"
                              : "21px",
                          background: red[500],
                          borderRadius: "50%",
                          position: "absolute",
                          top: notificationsCount < 100 ? 4 : 0,
                          right: 0,
                          color: "white",
                        }}
                        className="grid text-caption-1 items-center"
                      >
                        {notificationsCount}
                      </div>
                    )}
                    <Icon icon={ICONS.BELL} />
                  </div>
                </TippyWrapper>
              </Menu.Button>
            }
          >
            <NotificationsPopUp />
          </Dropdown>
          <Dropdown
            style={{ borderRadius: "8px", border: "1px solid #ced4da" }}
            trigger={
              <Menu.Button className="button icon-btn">
                <Icon tippyContent="Tools" icon={ICONS.TOOLS} />
              </Menu.Button>
            }
          >
            <div className="grid">
              {toolsActions.map((item, index) => (
                <Menu.Item key={index}>
                  <Link
                    to={item.path}
                    className="text-gray-900 py-2.5 px-5 hover:bg-gray-50"
                  >
                    {item.name}
                  </Link>
                </Menu.Item>
              ))}
            </div>
          </Dropdown>
          <Dropdown
            style={{ borderRadius: "8px", border: "1px solid #ced4da" }}
            trigger={
              <Menu.Button
                className={`button icon-btn ${
                  location.pathname.includes(`/account`) && "active"
                }`}
              >
                <Icon tippyContent="Account" icon={ICONS.USER} />
              </Menu.Button>
            }
          >
            <div className="grid">
              <div className="bg-white py-2.5 px-5" style={{ borderRadius: "8px" }}>
                <span className="text-sm font-medium text-gray-400 uppercase">
                  {account?.user?.firstName} {account?.user?.lastName}
                </span>
              </div>
              {userActions.map((item, index) => (
                <Menu.Item key={index}>
                  <Link
                    to={item.path}
                    className="text-gray-900 py-2.5 px-5 hover:bg-gray-50"
                  >
                    {item.name}
                  </Link>
                </Menu.Item>
              ))}
              <Menu.Item>
                <button
                  className="text-gray-900 py-2.5 px-5 hover:bg-gray-50 w-full text-left"
                  onClick={() => logout(instance, navigate)}
                >
                  Logout
                </button>
              </Menu.Item>
            </div>
          </Dropdown>
        </div>
      </div>
      <div className="main-menu">
        {selectedWorkspace && selectedWorkspace.customLogo && (
          <div className="absolute ml-5 h-1/2 py-2">
            {PoweredByLogo(width < 2000 ? "w-2/3 h-full" : "w-full h-full")}
          </div>
        )}
        {menuItems.map((item, index) => (
          <Fragment key={index}>
            <Link
              to={item.path}
              key={index}
              className={`menu-item ${location.pathname.includes(item.path) && "active"}`}
            >
              <Icon icon={item.icon} size={7} />
              {item.name}
            </Link>
          </Fragment>
        ))}
      </div>
      {!isLoading &&
        (!workspaceAssignedPlans || workspaceAssignedPlans?.length === 0) && (
          <div
            className={`no-plan-warning ${
              workspaceAssignedPlans?.length === 0 && availablePlans?.length === 0
                ? "bg-red-50 border-red-600"
                : "bg-yellow-50 border-yellow-600"
            }`}
          >
            <Icon
              icon={ICONS.INFORMATION_CIRCLE_SOLID}
              className={`${
                workspaceAssignedPlans?.length === 0 && availablePlans?.length === 0
                  ? "text-red-600"
                  : "text-yellow-600"
              }`}
            />
            <span className="font-medium text-caption-1">
              {!isLoading &&
              (!workspaceAssignedPlans || workspaceAssignedPlans?.length === 0) &&
              (!availablePlans || availablePlans?.length === 0) ? (
                <>
                  Warning! You don’t have any plan purchased and assigned to this
                  workspace. Because of this reason, you will be limited in actions until
                  this workspace has at least one plan added.&nbsp;
                  <span
                    onClick={() => navigate("/main/billing/plans")}
                    className="text-fuchsia-900 hover:cursor-pointer"
                  >
                    Go to Plans
                  </span>
                  &nbsp; and buy one accordingly to your needs.
                </>
              ) : (
                "Warning! You have not assigned any plan to this workspace. Because of this reason, you will be limited in actions until this workspace has at least one plan added by you or by other team members."
              )}
            </span>
          </div>
        )}
    </nav>
  )
}

const mapProps = (state: RootState) => ({
  account: state.account,
  workspaces: state.workspaces,
  workspaceAssignedPlans: state.plans.selectedWorkspaceAssignedPlans,
  availablePlans: state.plans.availablePlans,
  notifications: state.notifications.notifications,
  selectedWorkspaceId: state.workspaces.selectedWorkspaceId,
})

const mapDispatch = (dispatch: RootDispatch) => ({
  selectWorkspace: dispatch.workspaces.selectWorkspace,
  updateUser: dispatch.account.updateUser,
  getWorkspaceAssignedPlans: dispatch.plans.fetchSelectedWorkspaceAssignedPlans,
  getAvailablePlans: dispatch.plans.fetchAvailablePlans,
})

export const Navigation = connect(mapProps, mapDispatch)(NavigationComponent)
