import React, { FC, Suspense, useEffect } from "react"
import { Navigate, Route, Routes } from "react-router-dom"
import { useDispatch } from "react-redux"
import { RootDispatch } from "store"
import { SuspenseLoader } from "../../components/suspense-loader/suspense-loader"
import { lazyLoadRetry } from "../../utils/lazyLoadRetry"
import { abortController } from "api/api"

const ScoringRubrics = React.lazy(
  lazyLoadRetry(() => import("./scoring-rubrics/scoring-rubrics")),
)
const ManageDefaults = React.lazy(
  lazyLoadRetry(() => import("./manage-defaults/manage-defaults")),
)
const AddEditRubric = React.lazy(
  lazyLoadRetry(() => import("./add-edit-rubric/add-edit-rubric")),
)

export const CustomizeGrading: FC = () => {
  const dispatch = useDispatch<RootDispatch>()

  useEffect(() => {
    dispatch.retailers.fetchAllRetailers()
    return () => {
      abortController.abort()
    }
  }, [])

  return (
    <div className="m-page mt-5 h-full">
      <Suspense fallback={<SuspenseLoader delay={500} />}>
        <Routes>
          <Route path="/" element={<Navigate replace to="/main/grading/scorecards" />} />
          <Route path="/scorecards" element={<ScoringRubrics />} />
          <Route path="/manage-defaults" element={<ManageDefaults />} />
          <Route path=":rubricId/view" element={<AddEditRubric isView />} />
          <Route path=":rubricId" element={<AddEditRubric />} />
        </Routes>
      </Suspense>
    </div>
  )
}
