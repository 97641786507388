import { HeaderGroup, flexRender } from "@tanstack/react-table"
import { SortDirection } from "common/enums/SortDirection.enum"
import { ICONS, Icon } from "components/icon/icon"
import { ColumnSummary } from "./column-summary"

type TableHeadProps = {
  headerGroups: HeaderGroup<unknown>[]
  resizable?: boolean
  sortField?: string
  sortDirection?: SortDirection
  headerHeight?: number
  onSortChange?: (sortField: any) => void
}

export const TableHead = ({
  headerGroups,
  resizable,
  sortField,
  sortDirection,
  headerHeight,
  onSortChange,
}: TableHeadProps) => (
  <thead className="bg-gray-100 sticky top-0 text-left z-[1]">
    {headerGroups.map((headerGroup) => (
      <tr key={headerGroup.id}>
        {headerGroup.headers.map((header, index) => {
          // @ts-expect-error width
          const width = header.column.columnDef?.width
          // @ts-expect-error summary
          const summary = header.column.columnDef.summary
          // @ts-expect-error summaryFormat
          const summaryFormat = header.column.columnDef.summaryFormat
          // @ts-expect-error columnId
          const columnId = header.column.columnDef.columnId
          const isSortingField =
            sortField &&
            /* @ts-expect-error accessorKey type */
            sortField === (header.column.columnDef?.accessorKey as string)
          const colWidth = resizable || !width ? header.getSize() : width

          return (
            <th
              key={header.id + "-" + columnId}
              colSpan={header.colSpan}
              style={{
                width:
                  typeof colWidth === "number" && isSortingField
                    ? colWidth + 20
                    : colWidth,
                // @ts-expect-error flexGrow
                flexGrow: header.column.columnDef?.flexGrow ?? undefined,
                fontWeight: "semibold",
              }}
              className={`relative px-3 py-2 border-0 text-body ${
                index === 0 ? "rounded-l" : ""
              } ${index === headerGroup.headers.length - 1 ? "rounded-r" : ""}`}
            >
              {header.isPlaceholder ? null : (
                <div className={`h-${headerHeight} flex flex-col`}>
                  <div
                    className={`w-full flex justify-between ${
                      header.column.getCanSort() ? "cursor-pointer select-none" : ""
                    }`}
                    onClick={() =>
                      onSortChange &&
                      header.column.getCanSort() &&
                      onSortChange(header.column.columnDef)
                    }
                  >
                    <span
                      className="truncate"
                      title={header.column.columnDef.header as string}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </span>
                    {isSortingField ? (
                      <Icon
                        icon={
                          sortDirection === SortDirection.Ascending
                            ? ICONS.CHEVRON_UP
                            : ICONS.CHEVRON_DOWN
                        }
                      />
                    ) : null}
                  </div>

                  {summary !== undefined && (
                    <ColumnSummary summary={summary} summaryFormat={summaryFormat} />
                  )}
                </div>
              )}

              {resizable &&
                header.column.columnDef.header !== "" &&
                header.column.getCanResize() && (
                  <div
                    onMouseDown={header.getResizeHandler()}
                    onTouchStart={header.getResizeHandler()}
                    className={`absolute top-1.5 right-0 w-[3px] rounded h-2/3 bg-gray-200 cursor-col-resize select-none touch-none ${
                      header.column.getIsResizing() ? "bg-gray-300 opacity-1" : ""
                    }`}
                  />
                )}
            </th>
          )
        })}
      </tr>
    ))}
  </thead>
)
