import { FC, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import * as yup from "yup"
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { RootState } from "store"
import { AuditSubmitButtons } from "../audit-submit-buttons/audit-submit-buttons"
import { buildErrorObject, findSelectedRetailer, getSkuType } from "pages/audits/utils"
import { SkuType } from "common/enums/SkuType.enum"
import { SkuTypeSelector } from "./sku-type-selector/sku-type-selector"
import { EMPTY_SKU_ERROR_OBJECT } from "utils/constants"
import { SkuInput } from "./skus-input/skus-input"
import { ReviewUploads } from "./review-uploads/review-uploads"
import { UploadErrors } from "./upload-errors/upload-errors"
import { SkuDetails } from "common/types/SkuDetails.type"

type Props = {
  onSubmit: (values: any) => void
  handleBack: () => void
  isDisabledBack: boolean
}

const schema = yup.object({
  skusLoaded: yup.boolean().isTrue(),
})

export const UploadSkus: FC<Props> = ({
  onSubmit,
  handleBack,
  isDisabledBack,
}: Props) => {
  const selectedAudit = useSelector((state: RootState) => state.audits.selectedAudit)
  const retailers = useSelector((state: RootState) => state.retailers.allRetailers)
  const retailer = findSelectedRetailer(retailers, selectedAudit?.retailerId)
  const [skus, setSkus] = useState<(string | SkuDetails)[]>([])
  const [loadedSkusType, setLoadedSkusType] = useState(SkuType.URL)
  const [skuTypeSelection, setSkuTypeSelection] = useState(
    getSkuType(selectedAudit?.items, retailer),
  )
  const [errorObject, setErrorObject] = useState(EMPTY_SKU_ERROR_OBJECT)

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      skuType: getSkuType(selectedAudit?.items, retailer).toString(),
      skus: selectedAudit?.items || [],
      skusText: selectedAudit?.items?.join("\n"),
      skusLoaded: false,
    },
  })
  const { getValues, setValue, handleSubmit } = methods

  useEffect(() => {
    if (getValues().skus.length > 0) {
      if (!hasDuplicates()) {
        if (getValues().skus.length === skus.length) {
          setValue("skusLoaded", true)
        }
      }
    }
  }, [getValues().skus])

  useEffect(() => {
    if (selectedAudit?.items) {
      const newSkus = selectedAudit?.items.map((sku) => ({ value: sku, count: 1 }))
      setSkus(newSkus)
    }
  }, [selectedAudit?.items])

  const loadSkus = () => {
    const skusText = getValues().skusText
    const cleanSkusText = skusText?.replaceAll(" ", "")
    if (cleanSkusText) {
      if (skusText !== cleanSkusText) {
        setValue("skusText", cleanSkusText)
      }
      parseSkus(cleanSkusText)
      setLoadedSkusType(skuTypeSelection)
    }
  }

  useEffect(loadSkus, [])

  const parseSkus = (skusText: string) => {
    const newSkus = skusText.trim().split("\n")
    const validatedSkus = validateSkus(newSkus)
    setSkus(validatedSkus)
  }

  const clearAll = () => {
    setValue("skusText", "")
    setValue("skus", [])
    setSkus([])
    setErrorObject(EMPTY_SKU_ERROR_OBJECT)
  }

  const hasDuplicates = () => {
    return new Set(skus).size !== skus.length
  }

  const removeDuplicates = () => {
    const newSkusText = skus.map((sku) => typeof sku !== "string" && sku.value).join("\n")
    setValue("skusText", newSkusText)
    loadSkus()
  }

  const removeFaultyItems = (property: string) => {
    const newSkuArray = [] as string[]
    skus
      .filter((sku) => typeof sku !== "string" && !sku[property as keyof typeof sku])
      .forEach((sku) => {
        if (typeof sku !== "string") {
          for (let i = 0; i < sku.count; i++) {
            newSkuArray.push(sku.value)
          }
        }
      })
    const newSkusText = newSkuArray.join("\n") || ""
    if (newSkusText) {
      setValue("skusText", newSkusText)
      loadSkus()
    } else {
      clearAll()
    }
  }

  const validateSkus = (skus: string[]) => {
    const { newErrorState, skuErrors } = buildErrorObject(
      skus,
      retailer,
      skuTypeSelection,
      retailers,
      selectedAudit,
    )
    setErrorObject(newErrorState)
    if (
      JSON.stringify(newErrorState) === JSON.stringify(EMPTY_SKU_ERROR_OBJECT) &&
      skuErrors.length
    ) {
      setValue(
        "skus",
        skuErrors.map((item) => item.value),
      )
    }
    return skuErrors
  }

  const isSubmitDisabled =
    Boolean(hasDuplicates()) ||
    skus.length <= 0 ||
    JSON.stringify(errorObject) !== JSON.stringify(EMPTY_SKU_ERROR_OBJECT)

  return (
    <FormProvider {...methods}>
      <form className="add-edit-audit-card-content" onSubmit={handleSubmit(onSubmit)}>
        <div className="add-edit-audit-card-body">
          <div className="grid relative">
            <SkuTypeSelector
              retailer={retailer}
              skus={skus}
              loadedSkusType={loadedSkusType}
              setSkuTypeSelection={setSkuTypeSelection}
            />
            <SkuInput
              retailer={retailer}
              skuTypeSelection={skuTypeSelection}
              skus={skus}
              clearAll={clearAll}
              loadSkus={loadSkus}
            />
            {skus.length > 0 && (
              <ReviewUploads
                errorObject={errorObject}
                skus={skus}
                isSubmitDisabled={isSubmitDisabled}
              >
                <UploadErrors
                  errorObject={errorObject}
                  removeFaultyItems={removeFaultyItems}
                  removeDuplicates={removeDuplicates}
                />
              </ReviewUploads>
            )}
          </div>
        </div>
        <AuditSubmitButtons
          handleBack={handleBack}
          isDisabledBack={isDisabledBack}
          isDisabledSubmit={isSubmitDisabled}
        />
      </form>
    </FormProvider>
  )
}
