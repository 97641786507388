import { RematchDispatch } from "@rematch/core"
import { WORKSPACE_ID } from "api/api"
import {
  getFrequentlyUsedByAuditTypeApi,
  getRetailerByAuditTypeApi,
  getRetailerCategoriesApi,
  getRetailersApi,
} from "api/endpoints/retailers.api"
import { AuditType } from "common/enums/AuditType.enum"
import { RetailerModel } from "common/models/RetailerModel"

type State = Readonly<{
  allRetailers: RetailerModel[]
  frequentlyUsedRetailers: RetailerModel[]
  retailerCategories: string[]
}>

const model = {
  state: {
    allRetailers: [],
    frequentlyUsedRetailers: [],
    retailerCategories: [],
  } as State,
  reducers: {
    loadedAllRetailers: (state: State, payload: any): State => ({
      ...state,
      allRetailers: payload,
    }),
    loadedFrequentlyUsedRetailers: (state: State, payload: any): State => ({
      ...state,
      frequentlyUsedRetailers: payload,
    }),
    loadedRetailerCategories: (state: State, payload: any): State => ({
      ...state,
      retailerCategories: payload,
    }),
  },
  effects: (dispatch: RematchDispatch<any>) => ({
    async fetchAllRetailers() {
      const retailers = await getRetailersApi(WORKSPACE_ID)

      if (retailers) {
        dispatch.retailers.loadedAllRetailers(retailers)
      }
      return retailers
    },
    async fetchAllRetailersByAuditType(auditType: AuditType) {
      const retailers = await getRetailerByAuditTypeApi(auditType, WORKSPACE_ID)

      if (retailers) {
        dispatch.retailers.loadedAllRetailers(retailers)
      }
      return retailers
    },
    async fetchFrequentlyUsedRetailers(auditType: AuditType) {
      const retailers = await getFrequentlyUsedByAuditTypeApi(auditType, WORKSPACE_ID)

      if (retailers) {
        dispatch.retailers.loadedFrequentlyUsedRetailers(retailers)
      }
      return retailers
    },
    async fetchRetailerCategories(payload: string) {
      const retailerCategories = await getRetailerCategoriesApi(payload, WORKSPACE_ID)

      if (retailerCategories) {
        dispatch.retailers.loadedRetailerCategories(retailerCategories)
      }
      return retailerCategories
    },
  }),
}

export default model
