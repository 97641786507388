import { useEffect, useRef } from "react"
import { Routes, Route, useNavigate, useLocation } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { BillingAndPlans } from "pages/billing/billing-and-plans"
import { Audits } from "pages/audits/audits"
import { CustomizeGrading } from "pages/customize-grading/customize-grading"
import { Dashboard } from "pages/dashboard/dashboard"
import { ShareOfShelf } from "pages/share-of-shelf/share-of-shelf"
import { Workspaces } from "pages/workspaces/workspaces"
import { RootDispatch, RootState } from "store"
import { Navigation } from "components/navigation/navigation"
import { Catalogs } from "pages/catalogs/catalogs"
import { Alerts } from "pages/alerts/alerts"
import { FullPageLoader } from "components/full-page-loader/full-page-loader"
import { clearToken, getAccessToken } from "api/api"
import { getCurrentUserApi } from "api/endpoints/users.api"
import { DownloadsComponent } from "pages/downloads/downloads"
import { identify } from "../../utils/analytics"
import { Notifications } from "pages/notifications/notifications"
import { NetworkError } from "pages/error/error"
import { UserAccount } from "../account/user-account"
import { Invoices } from "../invoices/invoices"
import { ContentInsights } from "../new-dashboard/content-insights"
import { FieldMapping } from "../field-mapping/field-mapping"
import Reports from "pages/catalogs/audit-catalog/reports/reports"
import "./main.scss"

export const Main = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const ref = useRef(null)
  const dispatch: RootDispatch = useDispatch()
  const assignedPlans = useSelector(
    (state: RootState) => state.plans.selectedWorkspaceAssignedPlans,
  )
  const workspacesDataLoading = useSelector(
    (state: RootState) => state.workspaces.dataLoading,
  )

  useEffect(() => {
    if (!getAccessToken()) {
      clearToken()
      return navigate("/")
    }

    dispatch.workspaces.fetchWorkspaces()

    getCurrentUserApi("")
      .then((user) => {
        if (!user) {
          clearToken()
          return navigate("/")
        }

        dispatch.auditCatalog.resetAuditCatalogView()
        dispatch.account.loadedUserData(user)

        if (user.id) {
          identify(user.id || "", {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.userName,
            companyIndustry: user.companyIndustry,
            companyName: user.companyName,
            companyRole: user.companyRole,
            companyTitle: user.companyTitle,
            companyType: user.companyType,
            marketingSource: user.marketingSource,
            cs_hdyhas: user.marketingSource,
            userFlags: user.userFlags,
            identityId: user.identityId,
            stripeCustomerId: user.stripeCustomerId,
            defaultPaymentMethod: user.defaultPaymentMethod,
          })
        }
      })
      .catch(() => {
        clearToken()
        navigate("/")
      })
  }, [])

  return (
    <>
      <div className="main">
        <Navigation />
        <div
          className="main-content"
          style={{
            marginTop: assignedPlans && assignedPlans.length === 0 ? "151px" : "129px",
            height:
              assignedPlans && assignedPlans.length === 0
                ? "calc(100vh - 151px)"
                : "calc(100vh - 129px)",
            overflow:
              location.pathname.includes("/new-scorecard") ||
              (location.pathname.includes("/view") &&
                location.pathname.includes("/grading"))
                ? "hidden"
                : "auto",
          }}
          ref={ref}
        >
          <Routes>
            <Route path="dashboard/*" element={<Dashboard />} />
            <Route path="share-of-search/*" element={<ShareOfShelf />} />
            <Route path="workspaces/*" element={<Workspaces />} />
            <Route path="audits/*" element={<Audits />} />
            <Route path="reports" element={<Reports />} />
            <Route path="alerts/*" element={<Alerts />} />
            <Route path="grading/*" element={<CustomizeGrading />} />
            <Route path="billing/*" element={<BillingAndPlans />} />
            <Route path="account" element={<UserAccount />} />
            <Route path="invoices" element={<Invoices />} />
            <Route path="downloads/*" element={<DownloadsComponent />} />
            <Route path="audit-catalog/*" element={<Catalogs />} />
            <Route path="notifications" element={<Notifications />} />
            <Route path="error" element={<NetworkError />} />
            <Route path="content-insights" element={<ContentInsights />} />
            <Route
              path="field-mapping/:uploadId?/*"
              element={<FieldMapping ref={ref} />}
            />
          </Routes>
        </div>
      </div>
      <FullPageLoader loading={workspacesDataLoading} />
    </>
  )
}
