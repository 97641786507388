import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { RootDispatch, RootState } from "store"
import { ICONS, Icon } from "components/icon/icon"
import { useModal } from "context/modal-context"
import { DeleteAuditsModal } from "pages/audits/delete-audits-modal/delete-audits-modal"
import { CatalogRequestOptions } from "common/models/CatalogRequestOptions"

type Props = {
  data: any
}

export const ActionButtons = ({ data }: Props) => {
  const dispatch = useDispatch<RootDispatch>()
  const isViewer = useSelector(
    (state: RootState) => state.workspaces.isSelectedWorkspaceViewer,
  )
  const navigate = useNavigate()
  const { setModal } = useModal()

  const editAudit = (id: number) => {
    navigate(`/main/audits/${id}#1`)
  }

  const deleteSelectedAudit = (audit: any) => {
    setModal(<DeleteAuditsModal audit={audit} />)
  }

  const viewAuditItems = (jobName: string = "", retailer: string = "") => {
    const options = new CatalogRequestOptions({
      pageModel: new CatalogRequestOptions({}).pageModel,
      itemFilterModel: { jobNames: [jobName], dataSource: [retailer] },
    })

    dispatch.auditCatalog.setRedirectFromMyAudits(true)
    dispatch.auditCatalog.setRequestOptions(options)
    dispatch.auditCatalog.resetProducts()
    navigate("/main/audit-catalog")
  }

  return (
    <>
      <button
        title="Edit"
        type="button"
        className="button icon-btn"
        onClick={() => editAudit(data.id)}
        disabled={isViewer}
      >
        <Icon icon={ICONS.COG} />
      </button>
      <button
        title="View"
        type="button"
        className="button icon-btn"
        onClick={() => viewAuditItems(data.auditName, data.retailer)}
      >
        <Icon icon={ICONS.VIEW} />
      </button>
      <button
        title="Delete"
        type="button"
        className="button icon-btn"
        onClick={() => deleteSelectedAudit(data)}
        disabled={isViewer}
      >
        <Icon icon={ICONS.DELETE} />
      </button>
    </>
  )
}
