import { CatalogItemModel } from 'common/models/CatalogItemModel';
import { ItemKeywordsModel } from 'common/models/ItemKeywordsModel';
import { ItemTagsModel } from 'common/models/ItemTagsModel';
import { API } from "../api";

export const getItemDetailsApi = (workspaceId: string, itemId: string): Promise<CatalogItemModel> => API().get(`workspaces/${workspaceId}/items/${itemId}`);

export const updateItemKeywordsApi = (workspaceId: string, body: ItemKeywordsModel[]): Promise<boolean> => API().put(`workspaces/${workspaceId}/items/keywords`, body);

export const updateItemTagsApi = (workspaceId: string, body: ItemTagsModel[]): Promise<boolean> => API().put(`workspaces/${workspaceId}/items/tags`, body);

export const deleteItemsApi = (workspaceId: string, body: string[]): Promise<boolean> => API().delete(`workspaces/${workspaceId}/items/range`, body);

export const reTriggerItemsApi = (scorecardId: string): Promise<null> => API().post(`${scorecardId}/reTrigger`);
